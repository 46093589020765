import React, {Component, PureComponent} from 'react'
import {inject, observer} from 'mobx-react'

import {CloseButton} from './'

@inject('store') @observer
class DrawInterface extends Component {

	render() {

		const {ui__menuOpen} = this.props.store;

		return(
			<div className={ui__menuOpen ? "draw-interface" : "draw-interface draw-interface--hide"}>
				{/*<ColorPicker></ColorPicker>*/}
				<CloseButton></CloseButton>
				<DrawingGrid></DrawingGrid>
				{/**/}
			</div>
		)
	}
}


@inject('store') @observer
class DrawingGrid extends Component {
	constructor(props) {
		super(props);
		
		
		this.grid = [];
		this.count = 0;
		// this.color1 = null;
		// this.color2 = null;
		window.grid = this;

	}

	componentDidMount() {
		this.props.store.constructGrid();
	}

	initAnimation() {
		var cells = document.getElementsByClassName('grid-cell');
		var colors = document.getElementsByClassName('color-swatch');

		// this.color1 = colors[4];
		// this.color2 = colors[13];
		this.colors = colors; 
		this.count = 0;
		requestAnimationFrame(this.loop.bind(this, cells))
	}

 	loop(cells) {
		if (this.count >= cells.length) return;
		

		const color = this.colors[this.count * 3 % 16];
		

		color.click();
		cells[this.count].click()
		
		this.count++;
		requestAnimationFrame(this.loop.bind(this, cells))

 	}

	render() {
		if (!this.props.store.gridReady) {
			return null
		}

		const {ROWNUM, COLNUM, ui__menuOpen} = this.props.store;


		const rows = new Array(ROWNUM).fill(undefined);
		const cols = new Array(COLNUM).fill(undefined);

		return  (
		<div className="grid">
			<GridHeader></GridHeader>
			<div className="grid__inner">
				{rows.map((row, i) => {
					return <GridRow i={i} key={`gr-${i}`}></GridRow>
				})}
			</div>
		</div>
		)
	}
}

class GridHeader extends Component {

	render() {
		return(
			<div className="grid-header header">
				<div className="icon-wrap">
					<SpreadsheetIcon></SpreadsheetIcon>
				</div>
				
				<h1>Nicky Tesla [PORTFOLIO]</h1>
			</div>
		)
	}
}
class SpreadsheetIcon extends Component {

	render() {
		return(
			<div className="spreadsheet-icon">
				<span></span>
			</div>
		)
	}
}
@inject("store") @observer
class GridRow extends Component {
	render() {
		const {store: {COLNUM}, i} = this.props;
		
		const cols = new Array(COLNUM).fill(undefined);

		return (
		<div className="grid-row">
			{cols.map((val, j) => {
				return <GridCell key={`cell-${i}${j}`}val={val} i={i} j={j}/>
			})}
		</div>)
	}
}

@inject("store") @observer
class GridCell extends Component {
	handleClick = () => {
		const {i, j} = this.props;

		this.props.store.registerCell(i,j);
	}

	_getStyles() {
		const {val, store: {COLORS, gridContainer}, i, j} = this.props;
		const backgroundColor = COLORS[gridContainer[`r-${i}`][j]];

		const styles = {
			backgroundColor
		}

		return styles
	}



	render() {
		const styles = this._getStyles();
		const {i, j} = this.props;

		return(

			<div style={styles} className={`grid-cell r-${i} c-${j}`} onClick={this.handleClick}></div>
		)
	}
}




export default DrawInterface