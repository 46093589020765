import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

@inject('store') @observer
class IFrameWrapper extends Component {

	render() {
		const {ui__menuOpen} = this.props.store;

		return(
			<iframe className={ui__menuOpen ? "iframe--blur" : ""} src="https://docs.google.com/spreadsheets/d/1llBdCfdxjw2-VZL6IgHhOMJhKfEDH5YNiGtSttz96xc/edit?usp=sharing" frameBorder="0"></iframe>
		)
	}
}

export default IFrameWrapper