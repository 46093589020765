import {action, observable, computed} from 'mobx'

import * as firebase from 'firebase/app';
import 'firebase/database';

class RootStore {
	constructor() {
		this.COLORS = [
			"#EFEFEF", // grey
			"#E6B8AF", // pink light
			"#DD7E6B", // pink medium
			"#CC4124", // pink dark
			"#A61C01", // pink EXTRA dark
			"#F4CCCB", // pink 2 light
			"#EA9999", // pink 2 medium
			"#FF9901", // orange
			"#FCE5CD", // tan light
			"#FFF2CC", // yellow light
			"#FFE599", // yellow medium
			"#D9E9D3", // green light
			"#B6D7A8", // green medium
			"#93C47D", // green dark
			"#274E13", // green EXTRA dark
			"#C9B393", // brown light
			"#A48969", // brown medium
			"#EAD1DC", // purple light
		]

		var config = {
			apiKey: "AIzaSyB_NzFhXxMSZLh_M1lBntJJS4CrzCMjxcM",
			authDomain: "portfolio-3d488.firebaseapp.com",
			databaseURL: "https://portfolio-3d488.firebaseio.com",
			projectId: "portfolio-3d488",
			storageBucket: "portfolio-3d488.appspot.com",
			messagingSenderId: "946387219169"
		  // storageBucket: "bucket.appspot.com"
		};

		firebase.initializeApp(config);

		// Get a reference to the database service
		this.database = firebase.database();

		this.ROWNUM = 142;
		this.COLNUM = 15;

		this.gridContainer = {
		}
		
	}

	@observable ready = true;
	@observable ui__menuOpen = false;
	@observable grid = [];
	@observable gridReady = false;
	@observable activeColor = 0;
	
	
	@action constructGrid() {
		const ROWNUM = this.ROWNUM;
		const COLNUM = this.COLNUM;
		
		for (var i = 0; i < ROWNUM; i++) {
			this.gridContainer[`r-${i}`] = observable(new Array(COLNUM).fill(0));

			for (var j = 0; j < COLNUM; j++) {
				if (!this.grid[i]){
					this.grid[i] = [];
				}

				const row = this.grid[i];
				row[j] = 0;
			}
		}

		this.listenToDatabase()
	}

	// @computed get gridDimensions() {
	// 	return {rows: this.grid.length, cols: this.grid[0].length}
	// }

	@action registerCell(i,j) {

		// this.grid[i][j] = this.activeColor;

		this.gridContainer[`r-${i}`][j] = this.activeColor;
		this.updateDatabase(i,j)
	}

	@action openMenu() {

		this.ui__menuOpen = true;

	}

	@action closeMenu() {
	

		this.ui__menuOpen = false;

	}

	@action toggleMenu() {

		this.ui__menuOpen = !this.ui__menuOpen;

	}

	@action updateActiveColor(idx) {
		this.activeColor = idx
	}

	updateDatabase(i,j) {
		this.database.ref(`grid/grid/${i}/${j}`).set(this.activeColor);
		this.database.ref('clicks/totalClicks').transaction(function(currentClicks) {
		  // If node/clicks has never been set, currentRank will be `null`.
		  return (currentClicks || 0) + 1;
		});
		this.database.ref(`clicks/clicksByColor/${this.activeColor}`).transaction(function(currentClicks) {
		  // If node/clicks has never been set, currentRank will be `null`.
		  return (currentClicks || 0) + 1;
		});
	}

	@action listenToDatabase() {
		var gridRef = firebase.database().ref('grid/grid');
		let that = this;

		gridRef.once('value').then(function(snapshot) {
			const gridSnapshot = snapshot.val()
			gridSnapshot.forEach(that.updateRow);

			that.gridReady = true;
		});

		gridRef.on("child_changed", function(snapshot) {
			const rowIndex = snapshot.key;
			const newVals = snapshot.val(); 
			that.gridContainer[`r-${rowIndex}`].forEach((num, j) => {
				const newValue = newVals[j];
				that.gridContainer[`r-${rowIndex}`][j] = newValue;
			}) 
		});

	}

	@action.bound updateRow(row, i) {
		this.gridContainer[`r-${i}`] = observable(row);
	}
	
	@action updateRowVal(i, j) {

		this.gridContainer[`r-${i}`][j] = this.activeColor;

	}
}

const rootStore = new RootStore()

export default rootStore;
