import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

@inject('store') @observer
class DrawButton extends Component {
	constructor(props) {
		super(props)


		this.idx = 0;
	}
	_toggle = () => {
		if (this.props.store.ui__menuOpen) return 

		this.props.store.toggleMenu()
	}

	componentDidMount() {
		// setInterval(this._updateBackground.bind(this), 2000)
	}

	_updateBackground() {
		this.idx++;

		const {COLORS} = this.props.store;
		if (this.idx >= COLORS.length) {
			this.idx = 0;
		}

		this.setState({
			backgroundColor: COLORS[this.idx]
		});
	}

	render() {
		
		const {ui__menuOpen, COLORS, activeColor} = this.props.store;


		if (!this.props.store.gridReady) {
			return null
		}

		const styles = {
			backgroundColor: ui__menuOpen ? COLORS[activeColor] : "#fff"
		};


		return(
			<div className={ui__menuOpen ? "draw-button draw-button--open" : "draw-button"} onClick={this._toggle}>
				<div style={styles} className="draw-header header">
					<div className="icon-wrap">
						<ColorIcon></ColorIcon>
					</div>
					<h1>FILL</h1>
				</div>
				
				<ColorPicker/>
			</div>
		)
	}
}

const ColorIcon = () => {

	return(
		<div className="color-icon">
			<span></span>
		</div>
	)
	
}


class DrawIcon extends Component {

	render() {
		return(
			<div className="draw-icon">
				<span></span>
			</div>
		)
	}
}


@inject("store") @observer
class ColorPicker extends Component {
	_updateActiveColor(idx) {

		this.props.store.updateActiveColor(idx);

	}

	_renderColors() {
		const {COLORS, activeColor} = this.props.store;
		
		return COLORS.map((color, idx) => {

			return ( 
				<div 
					style={{backgroundColor: color}} 
					className={activeColor === idx ? "color-swatch color-swatch--active" : "color-swatch"}
					onClick={this._updateActiveColor.bind(this, idx)} />
					
			)
		})

	}

	render() {
		const {ui__menuOpen} = this.props.store;

		if (!this.props.store.gridReady) {
			return null
		}

		const colors = this._renderColors();
		return(
			<div className={ui__menuOpen ? "color-picker color-picker--show" : "color-picker"}>
				{colors}
			</div>
		)
	}
}


export default DrawButton