import React, { Component } from 'react';

import {IFrameWrapper} from './components/iframe'
import {DrawInterface, DrawButton} from './components/draw_interface'
import './App.css';

class App extends Component {
  render() {
    return (
      <div className="App">

        <div className="overlay"></div>
        <div className="disclaimer"><h3>**Edits made to the collaborative drawing will be incorporated every minute.</h3></div>
        <IFrameWrapper></IFrameWrapper>
      
        <DrawInterface></DrawInterface>
        <DrawButton></DrawButton>
      </div>
    );
  }
}





export default App;
