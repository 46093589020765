import React, {Component} from 'react'
import {inject, observer} from 'mobx-react'

@inject("store") @observer
class CloseButton extends Component {
	_close = () => {
		this.props.store.closeMenu()
	}

	render() {
		if (!this.props.store.gridReady) {
			return null
		}
		
		return (
			<div className="close-button header" onClick={this._close}>
				<div className="icon-wrap">
					<CloseIcon></CloseIcon>
				</div>
				<h1>EXIT</h1>
			</div>
		)
	}
}

const CloseIcon = () => {
	return(
		<img className="close-icon" src="/images/ex.svg" alt=""/>
	)
}

export default CloseButton